.landingpage .personas {
    width: 100%;
    height: 18rem;
    background-color: #F8CBAD;
    display: flex;
    justify-content: center;
}

.landingpage .personas > div {
    width: 33.33%;
    display: flex;
    justify-content: center;
    padding-top: 3.9rem;
}

.landingpage .personaBild {
    width: 4.6vw;
    height: 4.6vw;
    border-radius: 2.3vw;
    background-color: #C55A11;
    display: inline-block;
    vertical-align: top;
    margin-right: 1rem;
}

.landingpage .personaBild img {
    width: 4vw;
    height: 4vw;
    border-radius: 2vw;
    margin-top: .3vw;
    margin-left: .3vw;
}

.landingpage .personaBeschreibung {
    display: inline-block;
    width: 60%;
    margin-top: 1.4vw;
}

.landingpage .personaName {
    font-size: 1.8rem;
    margin-bottom: .5rem;
}

.landingpage .personaText {
    font-style: italic;
    font-size: 1.2rem;
    font-weight: 400;
}