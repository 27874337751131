.landingpage .overview {
    width: 100%;
    height: 33rem;
    padding-top: 5rem;
    background-color: #F8CBAD;
    display: flex;
}

.landingpage .overview div {
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.landingpage .overview div div{
    float: right;
    width: 50%;
    margin-left: 50%;
}

.landingpage .overview p {
    font-size: 3rem;
}

.landingpage .overview button {
    margin-top: 1rem;
    margin-left: 50%;
    width: 35%;
    height: 3.5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    color: white;
    font-weight: 900;
    background-color: #C55A11;
    border: none;
    border-radius: .8rem;
}


.landingpage #video {
    width: 60%;
}

.landingpage #video div{
    margin-left: 5%;
}

.landingpage #video div iframe{
    border: 4px solid #C55A11;
}