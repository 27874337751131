.landingpage #popupCreatorHintergrund {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .4;
}

.landingpage .popupCreator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 23rem;
    height: 30rem;
    background-color: #FAC295;
    border-radius: 12%;
    border-color: #f4b082;
    border-width: .3rem;
    border-style: solid;
}

.landingpage .popupCreator > div {
    background-color: #F8CBAD;
    padding: 1.5rem 0;
}

.landingpage .popupCreator > div > div {
    width: 20rem;
    margin-bottom: .5rem;
    margin-left: 1.5rem;
    padding-bottom: .4rem;
    display: flex;
    border-bottom: solid #fcba85 .1rem;
}

.landingpage #popupTextEnde {
    border: none;
    margin-bottom: 0;
}

.landingpage .popupCreator img {
    width: 10rem;
    height: 10rem;
    margin: 1.7rem 5.7rem 1.4rem 5.7rem;
    border-radius: 50%;
    border: solid #f4b082 .4rem;
}

.landingpage .popupCreator p {
    color: #fff7f1;
    font-size: 1.1rem;
    text-align: left;
}

.landingpage .popupCreator .valueName {
    width: 7.5rem;
    color: #C55A11;
}

.landingpage .value {
    width: 12.5rem;
    font-weight: 100;
}

.landingpage .popupCreator button {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(300%, -550%);
    width: 2.5rem;
    height: 2.5rem;
    background-color: #C55A11;
    border-style: none;
    border-radius: 50%;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 900;
}