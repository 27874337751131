.landingpage .header {
    position: fixed;
    width: 100%;
    height: 5rem;
    background-color: #F4B183;
    display: flex;
    z-index: 10;
}

.landingpage .header p {
    width: 40%;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    flex-direction: column;
}

.landingpage .header div {
    width: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.landingpage .header > div > div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.landingpage .header img {
    width: 3.8rem;
    height: 3.8rem;
}

.landingpage .header #spielName {
    text-align: left;
    margin-left: 2%;
}

.landingpage .header #unternehmenName {
    text-align: right;
    margin-right: 2%;
}