.launchQuellenBackground{
    width: 35rem ;
    height: 28rem ;
    border-radius: 5rem;
    z-index: 25;
    padding: 2rem;
    overflow-y: scroll;
    overflow-x: hidden;
    
    background-color: #FAC295;
    border: 5px solid #fff;
    
}

.launchQuellenPopUp a{
    font-size: 12px;
    background-color: transparent;
    color: #fff;
    font-weight: 400;
}

.launchQuellenPopUp ul{
    background-color: transparent;
    color: #fff;
}

.launchQuellenPopUp li{
    background-color: transparent;
    color: #fff;
}
.launchQuellenPopUp h1{
    color: #C55A11;
}

.launchQuellenPopUp table, th, td{
    border: 2px solid #C55A11;
    color: #C55A11;
    border-collapse: collapse;

}
