.landingpage {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-weight: 900;
    background-color: #F8CBAD;
}

.landingpage p {
    margin: 0;
}