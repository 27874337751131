.landingpage .footer {
    width: 100%;
    height: 5rem;
    background-color: #F4B183;
    display: flex;
    justify-content: space-around;


}

.landingpage .footer > p {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1.1rem;
}

.FooterButton{
    height: 5rem;
    background-color: #F4B183;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 900;
    border: 0px;
    text-decoration: underline;
}
.kontaktPopUp{
    width: 18rem;
    height: auto;
    background-color: #FAC295;
    border: 5px solid #fff;
    border-radius: 2.5rem;
    padding: 1rem;
    text-align: center;
    color: white;
    font-weight: 700;
}

