.landingpage .keypoints {
    width: 100%;
    height: 10rem;
    background-color: #FAC295;
    font-size: 2rem;
    display: flex;
    justify-content: center;
}

.landingpage .keypoints div {
    width: 25%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.landingpage .keypoints img {
    margin-bottom: 1rem;
    height: 3.5rem;
    width: auto;
}

.landingpage .keypoints p {
    text-align: center;
}

.landingpage .keypoints > div > div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.landingpage #iconCertified {
    width: 4.958rem;
}